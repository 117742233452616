import React from 'react'
import { useDoc } from './doc.context'
import { useTheme } from '../../context/theme.context'

function RelatedDocItem({ article }: any) {
  const { setActiveDoc } = useDoc()
  const { brandings } = useTheme()

  const handleSelectDoc = () => {
    setActiveDoc(article)
  }

  return (
    <li
      onClick={handleSelectDoc}
      className='border-b p-2 border-gray-600 text-gray-300 transition-all duration-200 hover:border-white hover:text-white'
    >
      <p
        style={{ color: brandings.textSecondaryColor }}
        className='block m-0 text-inherit'
      >
        {article.title}
      </p>
    </li>
  )
}

function RelatedDoc() {
  const { relatedDocs } = useDoc()
  const { brandings } = useTheme()

  return (
    <div>
      <aside className='hidden lg:block w-64 p-6 text-gray-200 border-l min-h-96'>
        <h2
          style={{ color: brandings.textColor }}
          className='text-xl leading-3 mb-4 text-white'
        >
          Related Article
        </h2>
        <div
          className='overflow-y-auto max-h-80 ' // Set max height for the container
          style={{ scrollbarWidth: 'none' }}
        >
          <ul className='space-y-4 cursor-pointer'>
            {relatedDocs.map((article) => (
              <RelatedDocItem article={article} key={article._id} />
            ))}
          </ul>
        </div>
      </aside>
    </div>
  )
}

export default RelatedDoc
