import React, { useEffect, useState } from 'react'
import { TABS, useDoc } from './doc.context'
import { useAppServices } from '../../hook/services'
import { useParams } from 'react-router-dom'
import Sidebar from './Sidebar'
import RelatedDoc from './RelatedDoc'
import { Tooltip } from 'react-tooltip'
import Swal from 'sweetalert2'
import { useTheme } from '../../context/theme.context'

export function PublishIcon({ size }: any) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      fill='currentColor'
      width={size || '20px'}
      height={size || '20px'}
      viewBox='0 0 1920 1920'
    >
      <path
        d='M860.16 1373.227 490.773 1003.84 641.6 853.013l218.56 218.56 453.653-453.653 150.827 150.827-604.48 604.48ZM960 0C429.76 0 0 429.76 0 960s429.76 960 960 960c530.133 0 960-429.76 960-960S1490.133 0 960 0Z'
        fillRule='evenodd'
      />
    </svg>
  )
}

export function UnpublishIcon({ size }: any) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      fill='currentColor'
      width={size || '20px'}
      height={size || '20px'}
      viewBox='0 0 1920 1920'
    >
      <path
        d='M213.333 960c0-167.36 56-321.707 149.44-446.4L1406.4 1557.227c-124.693 93.44-279.04 149.44-446.4 149.44-411.627 0-746.667-335.04-746.667-746.667m1493.334 0c0 167.36-56 321.707-149.44 446.4L513.6 362.773c124.693-93.44 279.04-149.44 446.4-149.44 411.627 0 746.667 335.04 746.667 746.667M960 0C429.76 0 0 429.76 0 960s429.76 960 960 960 960-429.76 960-960S1490.24 0 960 0'
        fillRule='evenodd'
      />
    </svg>
  )
}

export function DeleteIcon({ size }: any) {
  return (
    <svg
      fill='currentColor'
      width={size || '20px'}
      height={size || '20px'}
      viewBox='0 0 482.428 482.429'
    >
      <g>
        <g>
          <path d='M381.163,57.799h-75.094C302.323,25.316,274.686,0,241.214,0c-33.471,0-61.104,25.315-64.85,57.799h-75.098    c-30.39,0-55.111,24.728-55.111,55.117v2.828c0,23.223,14.46,43.1,34.83,51.199v260.369c0,30.39,24.724,55.117,55.112,55.117    h210.236c30.389,0,55.111-24.729,55.111-55.117V166.944c20.369-8.1,34.83-27.977,34.83-51.199v-2.828    C436.274,82.527,411.551,57.799,381.163,57.799z M241.214,26.139c19.037,0,34.927,13.645,38.443,31.66h-76.879    C206.293,39.783,222.184,26.139,241.214,26.139z M375.305,427.312c0,15.978-13,28.979-28.973,28.979H136.096    c-15.973,0-28.973-13.002-28.973-28.979V170.861h268.182V427.312z M410.135,115.744c0,15.978-13,28.979-28.973,28.979H101.266    c-15.973,0-28.973-13.001-28.973-28.979v-2.828c0-15.978,13-28.979,28.973-28.979h279.897c15.973,0,28.973,13.001,28.973,28.979    V115.744z' />
          <path d='M171.144,422.863c7.218,0,13.069-5.853,13.069-13.068V262.641c0-7.216-5.852-13.07-13.069-13.07    c-7.217,0-13.069,5.854-13.069,13.07v147.154C158.074,417.012,163.926,422.863,171.144,422.863z' />
          <path d='M241.214,422.863c7.218,0,13.07-5.853,13.07-13.068V262.641c0-7.216-5.854-13.07-13.07-13.07    c-7.217,0-13.069,5.854-13.069,13.07v147.154C228.145,417.012,233.996,422.863,241.214,422.863z' />
          <path d='M311.284,422.863c7.217,0,13.068-5.853,13.068-13.068V262.641c0-7.216-5.852-13.07-13.068-13.07    c-7.219,0-13.07,5.854-13.07,13.07v147.154C298.213,417.012,304.067,422.863,311.284,422.863z' />
        </g>
      </g>
    </svg>
  )
}

export function Spinner() {
  return (
    <div role='status'>
      <svg
        aria-hidden='true'
        className='w-4 h-4 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600'
        viewBox='0 0 100 101'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z'
          fill='currentColor'
        />
        <path
          d='M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z'
          fill='currentFill'
        />
      </svg>
      <span className='sr-only'>Loading...</span>
    </div>
  )
}

function DocView() {
  const { activeDoc, activeCat, setRelatedDocs, getLanguage } = useDoc()

  const [doc, setDoc] = useState({})
  const [loader, setLoader] = useState(true)
  const AppService = useAppServices()
  const { company_id } = useParams()
  const { brandings } = useTheme()

  const getDocsData = async () => {
    setLoader(true)
    const { response } = await AppService.docs.getdetails({
      query: `company_id=${company_id}&_id=${activeDoc._id}`,
    })

    // Handling Agency managed document
    let existingDoc = response.data?.interactedDocuments
    if (existingDoc) {
      existingDoc = {
        ...existingDoc.docId,
        snapshot: existingDoc,
      }
    }
    if (response) {
      const filter_doc =
        existingDoc ||
        response.data?.docs?.filter((doc) => doc._id == activeDoc._id)[0]
      const relatedDocs = response.data?.docs?.filter(
        (item) =>
          item.sub_category == filter_doc.sub_category &&
          item._id != filter_doc._id
      )
      setRelatedDocs(relatedDocs)
      setDoc(filter_doc)
      setLoader(false)
    } else {
      setLoader(false)
    }
  }

  useEffect(() => {
    if (activeDoc) getDocsData()
  }, [activeDoc?._id])

  return (
    <main className='flex-1 p-6 h-[calc(100vh-200px)] min-h-96 overflow-auto scrollbar-hidden'>
      {loader ? (
        <div role='status'>Loading ...</div>
      ) : (
        <>
          <div className='flex items-end gap-4 justify-between mb-6'>
            {activeCat.name && (
              <h1
                style={{ color: brandings.textColor }}
                className='text-xs text-white font-bold m-0'
              >
                {getLanguage(activeCat, 'name')}
              </h1>
            )}
          </div>

          <div>
            <h1
              style={{ color: brandings.textColor }}
              className='text-3xl text-white font-bold'
            >
              {getLanguage(doc, 'title')}
            </h1>
          </div>

          <div
            style={{ color: brandings.textSecondaryColor }}
            className='text-white'
            dangerouslySetInnerHTML={{
              __html: getLanguage(doc, 'doc'),
            }}
          ></div>
        </>
      )}
    </main>
  )
}

function Doc() {
  const { relatedDocs, openSideMenu, setOpenSideMenu } = useDoc()

  return (
    <>
      <div className='mb-2 block lg:hidden'>
        <button
          type='button'
          className='z-50 absolute inline-flex items-center text-sm text-gray-500 rounded-lg lg:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200'
          onClick={() => setOpenSideMenu(!openSideMenu)}
        >
          <span className='sr-only'>Open sidebar</span>
          <svg
            className='w-6 h-6'
            aria-hidden='true'
            fill='currentColor'
            viewBox='0 0 20 20'
            xmlns='http://www.w3.org/2000/svg'
          >
            <path
              clipRule='evenodd'
              fillRule='evenodd'
              d='M2 4.75A.75.75 0 012.75 4h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 4.75zm0 10.5a.75.75 0 01.75-.75h7.5a.75.75 0 010 1.5h-7.5a.75.75 0 01-.75-.75zM2 10a.75.75 0 01.75-.75h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 10z'
            />
          </svg>
        </button>
      </div>
      <div className='flex'>
        <Sidebar />
        <DocView />
        {relatedDocs?.length ? <RelatedDoc /> : ''}
      </div>
    </>
  )
}

export default Doc
