import React from 'react'
import { useTheme } from '../../context/theme.context'

const Footer = () => {
  const { brandings, generalInfos, urlGroups, logoUrl } = useTheme()
  const Links = [
    {
      title: 'Company',
      links: [
        { name: generalInfos.companyAddress, url: '#' },
        { name: generalInfos.companyPhone, url: '#' },
        { name: generalInfos.companyWebsite, url: '#' },
      ],
    },
  ]

  urlGroups.forEach((group) => {
    Links.push({
      title: group.groupName,
      links: group.links.map((link) => ({ name: link.name, url: link.url })), // Extract only the names of the links
    })
  })

  return (
    <footer className='py-10 border-t border-gray-700'>
      <div className='px-4 flex flex-col md:flex-row justify-between items-start space-y-8 md:space-y-0'>
        {/* Logo Section - Full width on mobile */}
        <div className='w-full md:w-1/5'>
          <div className='flex items-center space-x-3'>
            <img src={logoUrl} alt='logo' className='w-10 h-10' />
            <span className='text-xl font-semibold'>
              {generalInfos.companyName}
            </span>
          </div>
        </div>

        {/* Links Section - Stack vertically on mobile */}
        <div className='w-full md:w-4/5 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-8 md:flex md:justify-between'>
          {Links.map((section, index) => (
            <div key={index} className='w-full md:w-1/4'>
              <h4 className='text-xs uppercase font-semibold mb-3 text-white'>
                {section.title}
              </h4>
              <ul className='space-y-2'>
                {section.links.map((link, idx) => {
                  // ... existing link rendering logic ...
                  if (!link.url) {
                    return null // Or render an error message
                  }
                  let formattedUrl = link.url

                  if (
                    link.title !== 'Company' &&
                    !formattedUrl.startsWith('http')
                  ) {
                    formattedUrl = `https://${formattedUrl}`
                  }
                  return (
                    <li key={idx}>
                      <a
                        href={formattedUrl}
                        target='_blank'
                        rel='noopener noreferrer'
                        className='text-gray-500 text-sm hover:text-white transition-colors'
                      >
                        {link.name}
                      </a>
                    </li>
                  )
                })}
              </ul>
            </div>
          ))}
        </div>
      </div>
    </footer>
  )
}

export default Footer
