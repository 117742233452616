import React from 'react'
import { useTheme } from '../../../context/theme.context'
import { Spinner } from '../Doc'

function Button({
  onClick,
  type,
  disabled,
  processing,
  className,
  active,
  children,
}: any) {
  const { brandings, getHoverProps } = useTheme()
  {console.log(brandings.buttonBgColor,"buttoncolor")}

  return (
    <button
      type={type || 'button'}
      style={{
        color: brandings.buttonTextColor,
        backgroundColor: brandings.buttonBgColor,
      }}
      onMouseEnter={ (e) => (e.currentTarget.style.backgroundColor = brandings.buttonHoverColor)}
      onMouseLeave={(e) =>
        (e.currentTarget.style.backgroundColor =  brandings.buttonBgColor)}
      className={`w-full ${
        active ? 'bg-gray-700' : 'bg-gray-800'
      } focus:outline-none focus:ring-4 font-medium rounded-lg text-sm p-2 me-2 mb-4 hover:bg-gray-700 focus:ring-gray-700 ${className}`}
      onClick={onClick}
      disabled={processing || disabled}
    >
      {processing ? <Spinner /> : children}
    </button>
  )
}

export default Button
