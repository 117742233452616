import React from 'react'
import Navbar from './Navbar'
import { DocProvider, TABS, useDoc } from './doc.context'
import Doc from './Doc'
import Tabs from './Tabs'
import FAQs from './FAQs/FAQs'
import Footer from './footer'
import { useTheme } from '../../context/theme.context'
import { useAgency } from '../../context/agency.context'

const TabComponent = () => {
  // const { activeTab } = useDoc()
  const activeTab = 'doc'
  const components = {
    [TABS.DOC]: <Doc />,
    // [TABS.FAQ]: <FAQs />,
  }
  return components[activeTab]
}

const GitBookLayout = () => {
  const { brandings } = useTheme()
  const { agency } = useAgency()

  return (
    <DocProvider>
      <div
        style={{
          backgroundColor: brandings.pageBackground,
          color: brandings.textColor,
        }}
        className='h-screen overflow-auto relative'
      >
        <div className='max-w-[1536px] mx-auto px-6'>
          {/* Header */}
          <Navbar />

          {/* Main Content */}
          {/* <Tabs /> */}
          <TabComponent />
          {agency.generalInfos && <Footer />}
        </div>
      </div>
    </DocProvider>
  )
}

export default GitBookLayout
