/* eslint-disable @typescript-eslint/no-empty-function */
import React, { useState, createContext, useContext, useEffect } from 'react'
import { useAgency } from './agency.context'

const defaultdomain = 'https://www.example.com'

// const defaultBranding = {
//   pageBackground: '#1e1e1e',
//   textColor: '#ffffff',
//   textSecondaryColor: '#d1d5db',
//   buttonBgColor: '#1f2937',
//   buttonHoverColor: '#374151',
//   buttonTextColor: '#ffffff',
//   cardBgColor: '#1f2937',
//   textFieldBgColor: '#1f2937',
//   textFieldTextColor: '#ffffff',
//   textFieldBorderColor: '#374151',
// }
const defaultBranding = {
  pageBackground: '#F7F7F8',
  textColor: '#000000',
  textSecondaryColor: '#818892',
  buttonBgColor: '#3B82F6',
  buttonHoverColor: '#93C5FD',
  buttonTextColor: '#FFFFFE',
  cardBgColor: '#FFFFFF',
  textFieldBgColor: '#FFFFFF',
  textFieldTextColor: '#0E1726',
  textFieldBorderColor: '#E5E7EB',
}

const defaultGeneralInfo = {
  companyName: 'KNOWLEDGE BASE',
  companyLogo:
    "data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='white' height='30px' width='30px' version='1.1' viewBox='0 0 487.5 487.5' %3E%3Cg id='SVGRepo_bgCarrier' strokeWidth='0'%3E%3C/g%3E%3Cg id='SVGRepo_tracerCarrier' strokeLinecap='round' strokeLinejoin='round' %3E%3C/g%3E%3Cg id='SVGRepo_iconCarrier'%3E%3Cg%3E%3Cg%3E%3Cpath d='M437,12.3C437,5.5,431.5,0,424.7,0H126.3C84.4,0,50.4,34.1,50.4,75.9v335.7c0,41.9,34.1,75.9,75.9,75.9h298.5 c6.8,0,12.3-5.5,12.3-12.3V139.6c0-6.8-5.5-12.3-12.3-12.3H126.3c-28.3,0-51.4-23.1-51.4-51.4S98,24.5,126.3,24.5h298.5 C431.5,24.5,437,19,437,12.3z M126.3,151.8h286.2V463H126.3c-28.3,0-51.4-23.1-51.4-51.4V131.7 C88.4,144.2,106.5,151.8,126.3,151.8z'%3E%3C/path%3E%7B' '%7D %3Cpath d='M130.5,64.8c-6.8,0-12.3,5.5-12.3,12.3s5.5,12.3,12.3,12.3h280.1c6.8,0,12.3-5.5,12.3-12.3s-5.5-12.3-12.3-12.3H130.5z'%3E%3C/path%3E%3Cpath d='M178,397.7c6.3,2.4,13.4-0.7,15.8-7.1l17.9-46.8h62.7c0.5,0,0.9-0.1,1.3-0.1l17.9,46.9c1.9,4.9,6.5,7.9,11.4,7.9 c1.5,0,2.9-0.3,4.4-0.8c6.3-2.4,9.5-9.5,7.1-15.8l-54-141.2c-3-7.9-10.4-13-18.8-13c-8.4,0-15.8,5.1-18.8,13l-54,141.2 C168.5,388.2,171.7,395.2,178,397.7z M243.7,260l22.7,59.3h-45.3L243.7,260z'%3E%3C/path%3E%7B' '%7D %3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E",
  companyAddress: 'Los Angeles, CA',
  companyPhone: '+1 234 567 890',
  companyWebsite: 'https://www.example.com',
  poweredBy: 'Knowledge Base',
}

const initThemeContextPropsState = {
  domain: defaultdomain,
  setDomain: () => {},
  brandings: defaultBranding,
  generalInfos: defaultGeneralInfo,
  setGeneralInfo: () => {},
  setBrandings: () => {},
  urlGroups: [],
  setUrlGroups: () => {},
  logoUrl: '',
  setLogoUrl: () => {},
  getHoverProps: ({ active }) => {},
}

const ThemeContext = createContext(initThemeContextPropsState)

const useTheme = () => {
  return useContext(ThemeContext)
}

const ThemeProvider = ({ children }: any) => {
  const { agency } = useAgency()

  const [brandings, setBrandings] = useState(
    agency?.branding || defaultBranding
  )

  const [domain, setDomain] = useState(agency?.domain || defaultdomain)

  console.log(agency, 'generalInfos')
  const [generalInfos, setGeneralInfo] = useState(
    agency?.generalInfo || defaultGeneralInfo
  )

  const [urlGroups, setUrlGroups] = useState(agency?.urlGroups || [])
  const [logoUrl, setLogoUrl] = useState(
    agency?.logoUrl || defaultGeneralInfo.companyLogo
  )

  useEffect(() => {
    if (agency?.branding) {
      setBrandings(agency.branding)
    }
    if (agency?.generalInfo) {
      setGeneralInfo(agency.generalInfo)
    }
    if (agency?.urlGroups) {
      setUrlGroups(agency.urlGroups)
    }
    if (agency?.logoUrl) {
      setLogoUrl(agency.logoUrl)
    }

    if (agency?.domain) {
      setDomain(agency.domain)
    }
  }, [
    agency?.branding,
    agency?.generalInfo,
    agency?.urlGroups,
    agency?.domain,
    agency?.logoUrl,
  ])

  const [loader, setLoader] = useState(false)

  const onLoad = () => {
    console.log('component loaded')
  }
  useEffect(onLoad, [])

  const getHoverProps = ({ active }) => {
    return {
      onMouseEnter: (e) => (e.currentTarget.style.color = brandings.textColor),
      onMouseLeave: (e) =>
        (e.currentTarget.style.color = active
          ? brandings.textColor
          : brandings.textSecondaryColor),
    }
  }

  return (
    <ThemeContext.Provider
      value={{
        domain,
        setDomain,
        brandings,
        setBrandings,
        generalInfos,
        setGeneralInfo,
        urlGroups,
        setUrlGroups,
        logoUrl,
        setLogoUrl,
        getHoverProps,
      }}
    >
      {loader ? (
        <div className='w-full h-screen flex justify-center items-center flex-col bg-[#1E1E1E] text-gray-200'>
          <div>Loading...</div>
        </div>
      ) : (
        children
      )}
    </ThemeContext.Provider>
  )
}

export { ThemeProvider, useTheme }
