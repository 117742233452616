import React, { useEffect, useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useAppServices } from '../../../hook/services'
import { useFAQ } from './faq.context'
import { useDoc } from '../doc.context'
import { useTheme } from '../../../context/theme.context'
import Button from '../comp/Button'
import { SearchIcon } from '../Navbar'
import { Spinner } from '../Doc'
import Input from '../comp/Input'
import { Form, Formik } from 'formik'
import Swal from 'sweetalert2'
import { act } from 'react'
import PropTypes from 'prop-types'

Sidebar.propTypes = {
  show: PropTypes.bool.isRequired,
}

function ArrowIcon({
  isExpanded,
  size = 12,
}: {
  isExpanded: boolean,
  size?: number,
}) {
  return (
    <svg
      viewBox='0 0 1024 1024'
      width={size}
      height={size}
      className={`transition-transform duration-150 ${
        isExpanded ? 'rotate-[270deg]' : 'rotate-180'
      }`}
      fill='currentColor'
    >
      <path
        d='M768 903.232l-50.432 56.768L256 512l461.568-448 50.432 56.768L364.928 512z'
        fill='currentColor'
      ></path>
    </svg>
  )
}

function NavItem({
  title,
  children,
  active,
  disabled,
  expanded = false,
  cat,
  faq,
}: {
  title: string,
  active?: boolean,
  disabled?: boolean,
  expanded?: boolean,
  faq?: object,
  cat?: object,
  children?: React.ReactNode,
}) {
  const { brandings, getHoverProps } = useTheme()
  const { setActiveFaq, setActiveCat } = useFAQ()
  const [isExpanded, setIsExpanded] = useState(false)

  const handleSelectedDoc = () => {
    if (disabled) return
    if (children) {
      if (cat) setActiveCat(cat)
      return setIsExpanded(!isExpanded)
    }
    setActiveFaq(faq)
    setActiveCat(cat)
  }

  useEffect(() => {
    if (expanded) {
      setIsExpanded(expanded)
    }
  }, [expanded])

  return (
    <li className='text-sm'>
      <div
        style={{
          backgroundColor: active
            ? brandings.cardBgColor
            : brandings.pageBackground,
          color: active ? brandings.textColor : brandings.textSecondaryColor,
        }}
        {...getHoverProps({ active })}
        className={`flex items-center rounded-md justify-between p-2 ${
          active ? 'bg-gray-800' : ''
        } ${
          disabled
            ? 'opacity-50 cursor-default'
            : 'hover:bg-gray-800 hover:rounded-md cursor-pointer'
        }`}
        onClick={handleSelectedDoc}
      >
        <span>{title}</span>
        {children && (
          <div className=''>
            <ArrowIcon isExpanded={isExpanded} />
          </div>
        )}
      </div>
      {isExpanded && children && (
        <ul className='pl-2 border-l border-gray-600'>{children}</ul>
      )}
    </li>
  )
}

function DocList({ category }: any) {
  const { faqs, activeFaq } = useFAQ()
  return faqs
    ?.filter((faq) => faq.category._id == category._id)
    ?.map((faq) => (
      <NavItem
        key={faq._id}
        title={faq.question}
        faq={faq}
        cat={category}
        active={activeFaq._id == faq._id}
      />
    ))
}

function Modal({ open, onClose }: any) {
  const { brandings } = useTheme()
  const { company_id } = useParams()
  const AppService = useAppServices()
  const { faqs, setFaqs } = useFAQ()

  const initState = {
    question: '',
    answer: '',
    category: '',
  }

  const createFAQ = async (payload) => {
    const { response } = await AppService.k_faq.create({ payload })
    if (response) {
      faqs.unshift(response.data)
      setFaqs([...faqs])
    }
    return
  }

  const handleSubmit = async (values: any, { setSubmitting }: any) => {
    const payload = {
      name: values.category,
      companyId: company_id,
      type: 'agency',
    }

    const { response } = await AppService.k_faq_category.create({ payload })
    if (response) {
      await createFAQ({
        question: values.question,
        answer: values.answer,
        category: response.data,
        type: 'agency',
      })
      Swal.fire('Faq successfully added')
    }
    setSubmitting(false)
    onClose()
  }

  return open ? (
    <div
      className='fixed inset-0 flex items-center justify-center bg-gray-900 bg-opacity-75 backdrop-blur-lg z-50'
      onClick={(e) => {
        if (e.target === e.currentTarget) {
          onClose()
        }
      }}
    >
      <div className='bg-gray-800 p-8 rounded-xl shadow-xl w-[80%] max-w-3xl h-[80%] overflow-auto max-h-fit relative'>
        {/* Input Field with Search Icon */}
        <div
          style={{ color: brandings.textColor }}
          className='text-2xl font-bold'
        >
          Add FAQ Category
        </div>

        <Formik initialValues={{ ...initState }} onSubmit={handleSubmit}>
          {({ values, setFieldValue, isSubmitting }) => (
            <Form className='relative'>
              <div className='mt-4'>
                <div>Categroy Name</div>
                <Input
                  placeholder='Enter your category name'
                  onChange={(e) => setFieldValue('category', e.target.value)}
                  value={values.category}
                />
              </div>
              <div className='mt-4'>
                <div>Question</div>
                <Input
                  placeholder='Enter your question'
                  onChange={(e) => setFieldValue('question', e.target.value)}
                  value={values.question}
                />
              </div>
              <div className='mt-4'>
                <div>Answer</div>
                <textarea
                  placeholder='Answer'
                  className='w-full px-2.5 py-1.5 rounded-sm bg-[#2c2c2c] placeholder:text-[#aaaaaa] hover:!border-[#6a6a6a] transition-all ease-in'
                  style={{
                    border: '1px solid #4a4a4a',
                    color: brandings.textColor,
                  }}
                  onChange={(e) => setFieldValue('answer', e.target.value)}
                  value={values.answer}
                />
              </div>
              <div className='w-fit ml-auto'>
                <Button
                  type='submit'
                  processing={isSubmitting}
                  className='mt-4'
                >
                  Add Category
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  ) : (
    ''
  )
}

function SearchButton() {
  const { brandings } = useTheme()
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [filteredFaqs, setFilteredFaqs] = useState([])
  const { company_id, app_id } = useParams()
  const [searching, setSearching] = useState(false)
  const { faqs, setActiveFaq, setActiveCat } = useFAQ()

  const openModal = () => setIsModalOpen(true)
  const closeModal = () => setIsModalOpen(false)

  const searchDoc = async (e) => {
    const query = e.target.value.toLowerCase()
    setSearching(true)

    // Filter FAQs based on the search query
    const filtered = faqs.filter((faq) =>
      faq.question.toLowerCase().includes(query)
    )

    setFilteredFaqs(filtered)
    setSearching(false)
  }

  useEffect(() => {
    if (isModalOpen) {
      // Initialize with all FAQs when the modal opens
      setFilteredFaqs(faqs)
      setSearching(false)
    }
  }, [isModalOpen, faqs])

  const handleSelectFaq = (faq) => {
    setActiveFaq(faq)
    setActiveCat(faq.category)
    setIsModalOpen(false)
  }

  return (
    <div className='inline-block'>
      {/* Search Button */}
      <form className='items-center inline-flex'>
        <label htmlFor='voice-search' className='sr-only'>
          Search
        </label>
        <div className='relative w-full'>
          <div className='absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none'>
            <SearchIcon />
          </div>
          <Input
            type='text'
            id='voice-search'
            style={{ color: brandings.textColor }}
            className=' w-full ps-10 p-2.5 py-2'
            placeholder='Ask or Search...'
            required
            onClick={openModal} // Open modal on click of search bar
          />
        </div>
      </form>

      {/* Modal */}
      {isModalOpen && (
        <div
          style={{ backgroundColor: brandings.cardBgColor }}
          className='fixed inset-0 flex items-center justify-center bg-opacity-75 backdrop-blur-lg z-50'
          onClick={(e) => {
            // Close modal if clicking outside of the modal content
            if (e.target === e.currentTarget) {
              closeModal()
            }
          }}
        >
          <div
            className='p-8 rounded-xl shadow-xl w-[80%] max-w-3xl h-[80%]  max-h-fit relative'
            style={{ backgroundColor: brandings.cardBgColor, opacity: 0.75 }}
          >
            {/* Input Field with Search Icon */}
            <div className='relative'>
              <input
                type='text'
                id='modal-search'
                style={{
                  color: brandings.textColor,
                  backgroundColor: brandings.cardBgColor,
                  borderColor: brandings.borderColor,
                }}
                className='text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 pl-10 placeholder-gray-400'
                required
                onChange={searchDoc}
                autoFocus
              />
              <div className='absolute inset-y-0 left-4 flex items-center pointer-events-none'>
                <SearchIcon />
              </div>
            </div>

            {/* Search Results List */}
            {searching ? (
              <div className='flex justify-center items-center h-32'>
                <Spinner />
              </div>
            ) : (
              <ul className='mt-4 h-[inherit] overflow-y-auto space-y-2 text-sm text-gray-300'>
                {filteredFaqs.map((faq) => (
                  <li
                    key={faq._id}
                    onClick={() => handleSelectFaq(faq)}
                    style={{ color: brandings.textColor }}
                    className='p-2 hover:bg-gray-700 rounded-lg cursor-pointer flex items-center'
                  >
                    <SearchIcon />
                    {faq.question}
                  </li>
                ))}
              </ul>
            )}
          </div>
        </div>
      )}
    </div>
  )
}

// function Sidebar({ show }: any) {
//   const { categories, activeCat } = useFAQ()
//   const { brandings } = useTheme()

//   return (
//     <div className='z-50 flex-col md:flex-row flex'>
//       <aside
//         style={{
//           backgroundColor: brandings.pageBackground,
//         }}
//         className={`w-64 h-[calc(100vh-200px)] min-h-96 flex flex-col fixed
//            transition-transform overflow-auto ${
//              show ? 'translate-x-0' : '-translate-x-full'
//            } lg:relative left-0 lg:translate-x-0 mt-5`}
//       >
//         <div className='flex-1 p-6 pb-0 custom-scrollbar'>
//           <SearchButton className='mt-2 mb-2 md:mt-0' />

//           {categories?.map((category) => (
//             <>
//               <ul className='space-y-2 mb-8 '>
//                 <NavItem
//                   key={category._id}
//                   title={category.name}
//                   active={category._id === activeCat._id}
//                   expanded={category._id === activeCat._id}
//                   cat={category}
//                 >
//                   <DocList category={category} />
//                 </NavItem>
//               </ul>
//             </>
//           ))}
//         </div>
//       </aside>
//     </div>
//   )
// }

function Sidebar({ show }) {
  const { categories, activeCat, faqs, setActiveFaq, setActiveCat } = useFAQ()
  const { brandings } = useTheme()
  const [searchQuery, setSearchQuery] = useState('')

  // Compute filtered FAQs based on search query
  const filteredFaqs = useMemo(() => {
    if (!searchQuery) return []
    return faqs.filter((faq) =>
      faq.question.toLowerCase().includes(searchQuery.toLowerCase())
    )
  }, [faqs, searchQuery])

  // Handle FAQ selection
  const handleSelectFaq = (faq) => {
    setActiveFaq(faq)
    setActiveCat(faq.category)
    setSearchQuery('') // Clear search to return to category view
  }

  return (
    <div className='z-50 flex-col md:flex-row flex'>
      <aside
        style={{ backgroundColor: brandings.pageBackground }}
        className={`w-64 h-[calc(100vh-200px)] min-h-96 flex flex-col fixed 
          transition-transform overflow-auto ${
            show ? 'translate-x-0' : '-translate-x-full'
          } lg:relative left-0 lg:translate-x-0 mt-5`}
      >
        <div className='flex-1 p-6 pb-0 custom-scrollbar'>
          {/* Search Input */}
          <div className='relative w-full mb-4'>
            <div className='absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none'>
              <SearchIcon />
            </div>
            <Input
              type='text'
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              style={{ color: brandings.textColor }}
              className='w-full ps-10 p-2.5 py-2'
              placeholder='Ask or Search...'
            />
          </div>

          {/* Conditional Rendering */}
          {searchQuery ? (
            <ul className='space-y-2 text-sm text-gray-300'>
              {filteredFaqs.length > 0 ? (
                filteredFaqs.map((faq) => (
                  <li
                    key={faq._id}
                    onClick={() => handleSelectFaq(faq)}
                    className='p-2 hover:bg-gray-700 rounded-lg cursor-pointer'
                    style={{ color: brandings.textColor }}
                  >
                    {faq.question}
                  </li>
                ))
              ) : (
                <p className='text-center text-gray-500'>No FAQs found.</p>
              )}
            </ul>
          ) : (
            categories?.map((category) => (
              <ul key={category._id} className='space-y-2 mb-8'>
                <NavItem
                  title={category.name}
                  active={category._id === activeCat._id}
                  expanded={category._id === activeCat._id}
                  cat={category}
                >
                  <DocList category={category} />
                </NavItem>
              </ul>
            ))
          )}
        </div>
      </aside>
    </div>
  )
}

export default Sidebar
