import React from 'react'
import { useTheme } from '../../../context/theme.context'

function Input({ className, ...props }: any) {
  const { brandings } = useTheme()
  return (
    <input
      className={`w-full px-2.5 py-1.5 rounded-sm hover:!border-[#6a6a6a] transition-all ease-in' ${
        className ?? ''
      }`}
      style={{
        border: '1px solid #4a4a4a',
        color: brandings.textFieldTextColor,
        backgroundColor: brandings.textFieldBgColor,
        borderColor: brandings.textFieldBorderColor,
      }}
      {...props}
    />
  )
}

export default Input
