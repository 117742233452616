import React, {
  createContext,
  useContext,
  useState,
  useMemo,
  useEffect,
} from 'react'
import PropTypes from 'prop-types'
import Loader from '../components/Loader'
import { useParams } from 'react-router-dom'
import { useAppServices } from '../hook/services'

const LanguageContext = createContext()
LanguageContext.displayName = 'LanguageContext'

function LanguageProvider({ children }) {
  const AppService = useAppServices()
  const [language, setLanguage] = useState('english')
  const [loader, setLoader] = useState(false)
  const app_id = window.location.pathname.split('/')[1]
  const company_id = window.location.pathname.split('/')[2]
  const handleLanguage = async (e) => {
    setLanguage(e.target.value)
    const payload = {
      app_id: app_id,
      companyId: company_id,
      app_data: {
        language: e.target.value,
      },
    }
    const { response } = await AppService.agency.update_with_company_id({
      payload,
    })
    setLoader(false)
  }

  const value = useMemo(
    () => [language, handleLanguage],
    [language, handleLanguage]
  )

  return loader ? (
    <Loader />
  ) : (
    <LanguageContext.Provider value={value}>
      {children}
    </LanguageContext.Provider>
  )
}

function useLanguageInfo() {
  return useContext(LanguageContext) || []
}

LanguageProvider.propTypes = {
  children: PropTypes.node.isRequired,
}

export { LanguageProvider, useLanguageInfo }
