import axios from 'axios'
import env from '../config'
// import { setNotification, useNotifcation } from 'context/notification'
import { ReqMethods, ResponseStatus } from '../enums'
// import { useAuth, useLogout } from './auth'

const BASE_URL = `${env.API_URL}/agency_app`

function useServiceHandler() {
  // const [, dispatch] = useNotifcation()
  // const Logout = useLogout()

  return (fn) =>
    async (
      method,
      path,
      { query, payload, token, apiKey, toaster, message, error } = {}
    ) => {
      try {
        const res = await fn(method, path, { query, payload, token, apiKey })
        console.log('API - RESPONSE', res, toaster, message, error)
        // toaster &&
        //   setNotification(dispatch, {
        //     open: true,
        //     message: message || res.data.message,
        //     severity: 'success',
        //     title: 'Success',
        //   })

        return { response: res.data }
      } catch (err) {
        console.log('API- ERROR', err.response?.data || err)

        // expire error : jwt expired
        if (
          err.response &&
          err.response.status === ResponseStatus.UNAUTHORIZED
        ) {
          // setNotification(dispatch, {
          //   open: true,
          //   message: error || 'session expired',
          //   title: 'Error',
          //   severity: 'error',
          // })
          // setTimeout(Logout, 4000)
          return { error: err.response?.data || err }
        }

        const customError = err.response?.data?.error
          ? `${err.response?.data?.message} \n${err.response?.data?.error}`
          : err.response?.data?.message

        // toaster &&
        //   setNotification(dispatch, {
        //     open: true,
        //     message: error || customError || err.message,
        //     severity: 'error',
        //     title: 'Error',
        //   })
        return { error: err.response ? err.response.data : err }
      }
    }
}

function useCallService() {
  // const authToken = useAuth()
  const serviceHandler = useServiceHandler()

  const CallService = (
    method,
    path,
    { query, payload, token = true, apiKey = null }
  ) => {
    const pathname = query ? `${path}?${query}` : path
    const config = {}

    if (token) config.headers = { 'x-auth-token': `Bearer ${token}` }
    if (apiKey) config.headers = { apiKey }

    const details = {}

    if (payload) details.payload = payload
    details.config = config

    return axios[method](pathname, ...Object.values(details))
  }

  return serviceHandler(CallService)
}

function useAppServices() {
  const { GET, POST, PUT, DELETE } = ReqMethods
  const CallService = useCallService()

  /**
    Option for service is the object that could has the following properties
    query, payload, token, apiKey
  */

  const APIs = {
    auth: {
      login: (options) => CallService(POST, `${BASE_URL}/auth/login`, options),
      CreateTeam: (options) =>
        CallService(POST, `${BASE_URL}/auth/team`, options),
      GetTeam: (options) => CallService(POST, `${BASE_URL}/auth/team`, options),
    },
    user: {
      get: (options) => CallService(GET, `${BASE_URL}/user`, options),
      filter: (options) => CallService(GET, `${BASE_URL}/user/filter`, options),
      GetTeam: (options) => CallService(GET, `${BASE_URL}/user/teams`, options),
      update: (options) => CallService(PUT, `${BASE_URL}/user/teams`, options),
      delete: (options) => CallService(DELETE, `${BASE_URL}/user`, options),
    },
    notes_type: {
      get: (options) => CallService(GET, `${BASE_URL}/notes_type`, options),
      update: (options) => CallService(PUT, `${BASE_URL}/notes_type`, options),
      create: (options) => CallService(POST, `${BASE_URL}/notes_type`, options),
      delete: (options) =>
        CallService(DELETE, `${BASE_URL}/notes_type`, options),
    },
    app_setup: {
      create: (options) => CallService(POST, `${BASE_URL}/app_setup`, options),
      filter: (options) =>
        CallService(GET, `${BASE_URL}/app_setup/filter`, options),
      get: (options) => CallService(GET, `${BASE_URL}/app_setup`, options),
      update: (options) => CallService(PUT, `${BASE_URL}/app_setup`, options),
      delete: (options) =>
        CallService(DELETE, `${BASE_URL}/app_setup`, options),
    },
    faq: {
      create: (options) => CallService(POST, `${BASE_URL}/faq`, options),
      filter: (options) => CallService(GET, `${BASE_URL}/faq/filter`, options),
      get: (options) => CallService(GET, `${BASE_URL}/faq`, options),
      update: (options) => CallService(PUT, `${BASE_URL}/faq`, options),
      delete: (options) => CallService(DELETE, `${BASE_URL}/faq`, options),
    },
    appusers: {
      create: (options) => CallService(POST, `${BASE_URL}/appusers`, options),
      filter: (options) =>
        CallService(GET, `${BASE_URL}/appusers/filter`, options),
      get: (options) => CallService(GET, `${BASE_URL}/appusers`, options),
      update: (options) => CallService(PUT, `${BASE_URL}/appusers`, options),
      delete: (options) => CallService(DELETE, `${BASE_URL}/appusers`, options),
    },
    applocations: {
      create: (options) =>
        CallService(POST, `${BASE_URL}/applocations`, options),
      filter: (options) =>
        CallService(GET, `${BASE_URL}/applocations/filter`, options),
      get: (options) => CallService(GET, `${BASE_URL}/applocations`, options),
      update: (options) =>
        CallService(PUT, `${BASE_URL}/applocations`, options),
      delete: (options) =>
        CallService(DELETE, `${BASE_URL}/applocations`, options),
    },

    appinstalls: {
      create: (options) =>
        CallService(POST, `${BASE_URL}/appinstalls`, options),

      filter: (options) =>
        CallService(GET, `${BASE_URL}/appinstalls/filter`, options),
      getTokens: (options) =>
        CallService(GET, `${BASE_URL}/appinstalls/tokens`, options),
      getsso: (options) =>
        CallService(GET, `${BASE_URL}/appinstalls/sso`, options),
      get: (options) => CallService(GET, `${BASE_URL}/appinstalls`, options),
      update: (options) => CallService(PUT, `${BASE_URL}/appinstalls`, options),
      delete: (options) =>
        CallService(DELETE, `${BASE_URL}/appinstalls`, options),
    },
    appcompanies: {
      create: (options) =>
        CallService(POST, `${BASE_URL}/appcompanies`, options),
      filter: (options) =>
        CallService(GET, `${BASE_URL}/appcompanies/filter`, options),
      get: (options) => CallService(GET, `${BASE_URL}/appcompanies`, options),
      update: (options) =>
        CallService(PUT, `${BASE_URL}/appcompanies`, options),
      delete: (options) =>
        CallService(DELETE, `${BASE_URL}/appcompanies`, options),
    },
    k_faq_category: {
      create: (options) =>
        CallService(POST, `${BASE_URL}/k_faq_category`, options),
      filter: (options) =>
        CallService(GET, `${BASE_URL}/k_faq_category/filter`, options),
      get: (options) => CallService(GET, `${BASE_URL}/k_faq_category`, options),
      update: (options) =>
        CallService(PUT, `${BASE_URL}/k_faq_category`, options),
      delete: (options) =>
        CallService(DELETE, `${BASE_URL}/k_faq_category`, options),
    },
    faq_category: {
      create: (options) =>
        CallService(POST, `${BASE_URL}/faq_category`, options),
      filter: (options) =>
        CallService(GET, `${BASE_URL}/faq_category/filter`, options),
      get: (options) => CallService(GET, `${BASE_URL}/faq_category`, options),
      update: (options) =>
        CallService(PUT, `${BASE_URL}/faq_category`, options),
      delete: (options) =>
        CallService(DELETE, `${BASE_URL}/faq_category`, options),
    },
    tickets_category: {
      create: (options) =>
        CallService(POST, `${BASE_URL}/tickets_category`, options),
      filter: (options) =>
        CallService(GET, `${BASE_URL}/tickets_category/filter`, options),
      get: (options) =>
        CallService(GET, `${BASE_URL}/tickets_category`, options),
      update: (options) =>
        CallService(PUT, `${BASE_URL}/tickets_category`, options),
      delete: (options) =>
        CallService(DELETE, `${BASE_URL}/tickets_category`, options),
    },
    tickets_group: {
      create: (options) =>
        CallService(POST, `${BASE_URL}/tickets_group`, options),
      filter: (options) =>
        CallService(GET, `${BASE_URL}/tickets_group/filter`, options),
      get: (options) => CallService(GET, `${BASE_URL}/tickets_group`, options),
      update: (options) =>
        CallService(PUT, `${BASE_URL}/tickets_group`, options),
      delete: (options) =>
        CallService(DELETE, `${BASE_URL}/tickets_group`, options),
    },
    tickets_type: {
      create: (options) =>
        CallService(POST, `${BASE_URL}/tickets_type`, options),
      get: (options) => CallService(GET, `${BASE_URL}/tickets_type`, options),
      update: (options) =>
        CallService(PUT, `${BASE_URL}/tickets_type`, options),
      delete: (options) =>
        CallService(DELETE, `${BASE_URL}/tickets_type`, options),
    },
    health_plan: {
      create: (options) =>
        CallService(POST, `${BASE_URL}/health_plan`, options),
      get: (options) => CallService(GET, `${BASE_URL}/health_plan`, options),
      update: (options) => CallService(PUT, `${BASE_URL}/health_plan`, options),
      delete: (options) =>
        CallService(DELETE, `${BASE_URL}/health_plan`, options),
    },
    health_features: {
      create: (options) =>
        CallService(POST, `${BASE_URL}/health_features`, options),
      filter: (options) =>
        CallService(GET, `${BASE_URL}/health_features/filter`, options),
      get: (options) =>
        CallService(GET, `${BASE_URL}/health_features`, options),
      update: (options) =>
        CallService(PUT, `${BASE_URL}/health_features`, options),
      delete: (options) =>
        CallService(DELETE, `${BASE_URL}/health_features`, options),
    },
    app: {
      get: (options) => CallService(GET, `${BASE_URL}/app`, options),
      update: (options) => CallService(PUT, `${BASE_URL}/app`, options),
      create: (options) => CallService(POST, `${BASE_URL}/app`, options),
      delete: (options) => CallService(DELETE, `${BASE_URL}/app`, options),
      filter: (options) => CallService(GET, `${BASE_URL}/app/filter`, options),
    },
    languages: {
      get: (options) => CallService(GET, `${BASE_URL}/languages`, options),
      update: (options) => CallService(PUT, `${BASE_URL}/languages`, options),
      create: (options) => CallService(POST, `${BASE_URL}/languages`, options),
      delete: (options) =>
        CallService(DELETE, `${BASE_URL}/languages`, options),
      filter: (options) =>
        CallService(GET, `${BASE_URL}/languages/filter`, options),
    },
    tooltip: {
      get: (options) => CallService(GET, `${BASE_URL}/tooltip`, options),
      update: (options) => CallService(PUT, `${BASE_URL}/tooltip`, options),
      update_index: (options) =>
        CallService(PUT, `${BASE_URL}/tooltip/update_index`, options),
      create: (options) => CallService(POST, `${BASE_URL}/tooltip`, options),
      delete: (options) => CallService(DELETE, `${BASE_URL}/tooltip`, options),
      filter: (options) =>
        CallService(GET, `${BASE_URL}/tooltip/filter`, options),
    },
    pages: {
      get: (options) => CallService(GET, `${BASE_URL}/pages`, options),
      update: (options) => CallService(PUT, `${BASE_URL}/pages`, options),
      create: (options) => CallService(POST, `${BASE_URL}/pages`, options),
      delete: (options) => CallService(DELETE, `${BASE_URL}/pages`, options),
      filter: (options) =>
        CallService(GET, `${BASE_URL}/pages/filter`, options),
    },
    app_listing_category: {
      get: (options) =>
        CallService(GET, `${BASE_URL}/app_listing_category`, options),
      update: (options) =>
        CallService(PUT, `${BASE_URL}/app_listing_category`, options),
      create: (options) =>
        CallService(POST, `${BASE_URL}/app_listing_category`, options),
      delete: (options) =>
        CallService(DELETE, `${BASE_URL}/app_listing_category`, options),
      filter: (options) =>
        CallService(GET, `${BASE_URL}/app_listing_category/filter`, options),
    },
    app_listing: {
      get: (options) => CallService(GET, `${BASE_URL}/app_listing`, options),
      update: (options) => CallService(PUT, `${BASE_URL}/app_listing`, options),
      create: (options) =>
        CallService(POST, `${BASE_URL}/app_listing`, options),
      delete: (options) =>
        CallService(DELETE, `${BASE_URL}/app_listing`, options),
      filter: (options) =>
        CallService(GET, `${BASE_URL}/app_listing/filter`, options),
    },
    vote: {
      get: (options) => CallService(GET, `${BASE_URL}/vote`, options),
      getappvotes: (options) =>
        CallService(GET, `${BASE_URL}/vote/getappvotes`, options),
      update: (options) => CallService(PUT, `${BASE_URL}/vote`, options),
      create: (options) => CallService(POST, `${BASE_URL}/vote`, options),
      delete: (options) => CallService(DELETE, `${BASE_URL}/vote`, options),
      filter: (options) => CallService(GET, `${BASE_URL}/vote/filter`, options),
    },
    snapshot: {
      get: (options) => CallService(GET, `${BASE_URL}/snapshot`, options),
      create: (options) => CallService(POST, `${BASE_URL}/snapshot`, options),
      update: (options) => CallService(PUT, `${BASE_URL}/snapshot`, options),
      filter: (options) =>
        CallService(GET, `${BASE_URL}/snapshot/filter`, options),
      delete: (options) => CallService(DELETE, `${BASE_URL}/snapshot`, options),
    },
    docs: {
      getlanguages: (options) =>
        CallService(GET, `${BASE_URL}/docs/getlanguages`, options),
      get: (options) => CallService(GET, `${BASE_URL}/docs`, options),
      getdetails: (options) =>
        CallService(GET, `${BASE_URL}/docs/details`, options),
      createDocs: (options) => CallService(POST, `${BASE_URL}/docs`, options),
      updateDocs: (options) => CallService(PUT, `${BASE_URL}/docs`, options),
      Docs: (options) => CallService(POST, `${BASE_URL}/docs`, options),
      get_filter: (options) =>
        CallService(GET, `${BASE_URL}/docs/filter`, options),
      get_filter_cat: (options) =>
        CallService(GET, `${BASE_URL}/docs/category/filter`, options),
      delete: (options) =>
        CallService(DELETE, `${BASE_URL}/docs/delete`, options),
      take_snapshot: (options) =>
        CallService(POST, `${BASE_URL}/docs/take_snapshot`, options),
      handle_status: (options) =>
        CallService(POST, `${BASE_URL}/docs/status`, options),
      query_doc: (options) =>
        CallService(GET, `${BASE_URL}/docs/query`, options),
        createcomment: (options) =>
          CallService(POST, `${BASE_URL}/docs/comments/create`, options),
        getByDoc: (options) =>
          CallService(GET, `${BASE_URL}/docs/comments`, options),
        getUserDetails: (options) =>
          CallService(GET, `${BASE_URL}/docs/user`, options),
      
    },
    k_faq: {
      get: (options) => CallService(GET, `${BASE_URL}/k_faq`, options),
      update: (options) => CallService(PUT, `${BASE_URL}/k_faq`, options),
      create: (options) => CallService(POST, `${BASE_URL}/k_faq`, options),
      filter: (options) =>
        CallService(GET, `${BASE_URL}/k_faq/filter`, options),
      make_active: (options) =>
        CallService(POST, `${BASE_URL}/k_faq/make_active`, options),
      delete: (options) =>
        CallService(DELETE, `${BASE_URL}/k_faq/delete`, options),
    },
    docs_category: {
      createDocs: (options) =>
        CallService(POST, `${BASE_URL}/docs/category`, options),
      updateDocs: (options) =>
        CallService(PUT, `${BASE_URL}/docs/category`, options),
      filter: (options) =>
        CallService(GET, `${BASE_URL}/docs/category/filter`, options),
      delete: (options) =>
        CallService(DELETE, `${BASE_URL}/docs/category/delete`, options),
    },
    docs_sub_category: {
      create: (options) =>
        CallService(POST, `${BASE_URL}/docs/subcategory`, options),
      update: (options) =>
        CallService(PUT, `${BASE_URL}/docs/subcategory`, options),
      filter: (options) =>
        CallService(GET, `${BASE_URL}/docs/subcategory/filter`, options),
      delete: (options) =>
        CallService(DELETE, `${BASE_URL}/docs/subcategory/delete`, options),
    },
    agency_articles: {
      createDocs: (options) =>
        CallService(POST, `${BASE_URL}/agency_articles`, options),
      updateDocs: (options) =>
        CallService(PUT, `${BASE_URL}/agency_articles`, options),
      Docs: (options) =>
        CallService(POST, `${BASE_URL}/agency_articles`, options),
      get_filter: (options) =>
        CallService(GET, `${BASE_URL}/agency_articles/filter`, options),
      get_filter_cat: (options) =>
        CallService(
          GET,
          `${BASE_URL}/agency_articles/category/filter`,
          options
        ),
      delete: (options) =>
        CallService(DELETE, `${BASE_URL}/agency_articles/delete`, options),
    },
    agency_articles_category: {
      createDocs: (options) =>
        CallService(POST, `${BASE_URL}/agency_articles/category`, options),
      updateDocs: (options) =>
        CallService(PUT, `${BASE_URL}/agency_articles/category`, options),
      delete: (options) =>
        CallService(
          DELETE,
          `${BASE_URL}/agency_articles/category/delete`,
          options
        ),
    },
    agency_articles_sub_category: {
      create: (options) =>
        CallService(POST, `${BASE_URL}/agency_articles/subcategory`, options),
      update: (options) =>
        CallService(PUT, `${BASE_URL}/agency_articles/subcategory`, options),
      filter: (options) =>
        CallService(
          GET,
          `${BASE_URL}/agency_articles/subcategory/filter`,
          options
        ),
      delete: (options) =>
        CallService(
          DELETE,
          `${BASE_URL}/agency_articles/subcategory/delete`,
          options
        ),
    },
    request_app: {
      get: (options) => CallService(GET, `${BASE_URL}/requestapp`, options),
      update: (options) => CallService(PUT, `${BASE_URL}/requestapp`, options),
      create: (options) => CallService(POST, `${BASE_URL}/requestapp`, options),
      delete: (options) =>
        CallService(DELETE, `${BASE_URL}/requestapp`, options),
      filter: (options) =>
        CallService(GET, `${BASE_URL}/requestapp/filter`, options),
    },
    categories: {
      get: (options) =>
        CallService(GET, `${BASE_URL}/category/getsnaphotcategories`, options),
      update: (options) =>
        CallService(POST, `${BASE_URL}/category/update/${options.id}`, options),
      create: (options) =>
        CallService(POST, `${BASE_URL}/category/submit`, options),
      delete: (options) =>
        CallService(POST, `${BASE_URL}/category/delete/${options.id}`, options),
      filter: (options) =>
        CallService(GET, `${BASE_URL}/category/filter`, options),
    },
    events: {
      get: (options) => CallService(GET, `${BASE_URL}/events`, options),
      update: (options) =>
        CallService(POST, `${BASE_URL}/events/update/${options.id}`, options),
      create: (options) =>
        CallService(POST, `${BASE_URL}/events/submit`, options),
      delete: (options) =>
        CallService(POST, `${BASE_URL}/events/delete/${options.id}`, options),
      filter: (options) =>
        CallService(GET, `${BASE_URL}/events/filter`, options),
    },
    offer: {
      get: (options) => CallService(GET, `${BASE_URL}/offer`, options),
      update: (options) => CallService(PUT, `${BASE_URL}/offer`, options),
      create: (options) => CallService(POST, `${BASE_URL}/offer`, options),
      delete: (options) => CallService(DELETE, `${BASE_URL}/offer`, options),
      filter: (options) =>
        CallService(GET, `${BASE_URL}/offer/filter`, options),
    },
    brand: {
      get: (options) => CallService(GET, `${BASE_URL}/brand`, options),
      update: (options) => CallService(PUT, `${BASE_URL}/brand`, options),
      superadmin: (options) =>
        CallService(PUT, `${BASE_URL}/brand/superadmin`, options),
      filter: (options) =>
        CallService(GET, `${BASE_URL}/brand/filter`, options),
    },
    agency: {
      get: (options) => CallService(GET, `${BASE_URL}/agency`, options),
      getProfile: (options) =>
        CallService(GET, `${BASE_URL}/agency/profile`, options),
      update: (options) => CallService(PUT, `${BASE_URL}/agency`, options),
      getProducts: (options) =>
        CallService(PUT, `${BASE_URL}/agency/getproducts`, options),
    },
    superadmin: {
      getProducts: (options) =>
        CallService(
          GET,
          `${BASE_URL}/snapshot/superadmin_settings/workflows`,
          options
        ),
    },
    utils: {
      upload_image: (options) =>
        CallService(POST, `${BASE_URL}/utils/upload/image`, options),
    },
    stripe: {
      // product: (options) => CallService(GET, `${BASE_URL}/services/stripe/products`, options),
      // getCustomer: (options) => CallService(GET, `${BASE_URL}/services/stripe/customers`, options),
      integrateAcocunt: (options) =>
        CallService(
          GET,
          `${BASE_URL}/snapshot/agency/stripe/integrate`,
          options
        ),
      // addSubscription: (options) =>
      //   CallService(POST, `${BASE_URL}/services/stripe/subscription/add`, options),
    },
    services: {
      ghl: {
        call_service: (options) =>
          CallService(POST, `${BASE_URL}/services/ghl/`, options),
      },
    },
  }

  return APIs
}
const useUploadImage = () => {
  const AppService = useAppServices()
  return ({ toaster, file, desiredPath }) => {
    const form = new FormData()
    form.append('image', file, file.name)
    return AppService.utils.upload_image({
      toaster,
      payload: form,
      query: `desiredPath=${desiredPath}`,
    })
  }
}
export { useAppServices, useCallService, useUploadImage }
