import React, { useEffect, useState } from 'react'
import Sidebar from './Sidebar'
import { FAQProvider, useFAQ } from './faq.context'
import { useTheme } from '../../../context/theme.context'

function FAQView() {
  const { brandings } = useTheme()
  const { activeCat, activeFaq, faqs } = useFAQ()
  const [addNewFAQ] = useState(false)

  if (!addNewFAQ) {
    const targetElement = document.getElementById(activeFaq?._id)
    targetElement?.scrollIntoView({ behavior: 'smooth', block: 'center' })
  } else {
    const form = document.getElementById('new-faq')
    form?.scrollIntoView({ behavior: 'smooth', block: 'center' })
  }

  useEffect(() => {
    const targetElement = document.getElementById(activeFaq?._id)
    targetElement?.scrollIntoView({ behavior: 'smooth', block: 'center' })
  }, [activeFaq])

  return (
    <main className='flex-1 p-6 pt-0 h-[calc(100vh-200px)] min-h-96 overflow-auto scrollbar-hidden'>
      <div
        style={{ backgroundColor: brandings.pageBackground }}
        className='flex items-center gap-4 justify-between py-4 pt-8 sticky top-0'
      >
        <h1
          style={{ color: brandings.textColor }}
          className='text-xs font-bold m-0'
        >
          {activeCat.name || ''}
        </h1>
      </div>
      {faqs
        .filter((faq) => faq.category._id === activeCat._id)
        .map((faq, index) => (
          <div
            id={faq._id}
            key={faq._id}
            className={`border-b ${index == 0 ? '' : 'my-6'} pb-6 ${
              faq._id === activeFaq?._id ? 'animate-[bounce_1s_ease_1]' : ''
            }`}
          >
            <div className='flex items-center justify-between'>
              <h1
                style={{ color: brandings.textColor }}
                className='text-2xl font-bold'
              >
                Q: {faq.question}
              </h1>
            </div>
            <div>{faq.answer}</div>
          </div>
        ))}
    </main>
  )
}

function FAQs() {
  const [openSideMenu, setOpenSideMenu] = useState(false)

  return (
    <>
      <FAQProvider>
        <div className='mb-2 block lg:hidden'>
          <button
            type='button'
            className='z-50 absolute inline-flex items-center text-sm text-gray-500 rounded-lg lg:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200'
            onClick={() => setOpenSideMenu(!openSideMenu)}
          >
            <span className='sr-only'>Open sidebar</span>
            <svg
              className='w-6 h-6'
              aria-hidden='true'
              fill='currentColor'
              viewBox='0 0 20 20'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path
                clipRule='evenodd'
                fillRule='evenodd'
                d='M2 4.75A.75.75 0 012.75 4h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 4.75zm0 10.5a.75.75 0 01.75-.75h7.5a.75.75 0 010 1.5h-7.5a.75.75 0 01-.75-.75zM2 10a.75.75 0 01.75-.75h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 10z'
              />
            </svg>
          </button>
        </div>
        <div className='flex px-4'>
          {/* Sidebar */}
          <Sidebar show={openSideMenu} />
          {/* Main Section */}
          <FAQView />
        </div>
      </FAQProvider>
    </>
  )
}

export default FAQs
