import React, { useEffect, useState } from 'react'
import { useAppServices } from '../../hook/services'
import { TABS, useDoc } from './doc.context'
import Swal from 'sweetalert2'
import { useParams } from 'react-router-dom'
import { useTheme } from '../../context/theme.context'
import Button from './comp/Button'
import { DeleteIcon, PublishIcon, Spinner, UnpublishIcon } from './Doc'
import { useAgency } from '../../context/agency.context'
import Input from './comp/Input'
import { SearchIcon } from './Navbar'
import PropTypes from 'prop-types'

SearchButton.propTypes = {
  setSearchQuery: PropTypes.func.isRequired, // A required function
  className: PropTypes.string, // An optional string
}

const getSubCatStatus = (doc) => {
  if (doc.snapshot && doc.snapshot.status == 'active') return 'inactive'
  if (doc.snapshot && doc.snapshot.status == 'inactive') return 'active'
  if (!doc.snapshot && doc.status == 'active') return 'inactive'
  if (!doc.snapshot && doc.status == 'draft') return 'active'
}

export const isDisabled = (doc) => {
  if (!doc.snapshot && doc.status == 'inactive') return true
  if (doc.snapshot && doc.snapshot.status == 'inactive') return true
  return false
}

const isDisabledSubCat = (doc) => {
  if (!doc.snapshot && doc.status == 'inactive') return true
  if (doc.snapshot && doc.snapshot.status == 'inactive') return true
  return false
}

const hasSubCat = (cat, data) => {
  const subcats = data?.sub_category.filter(
    (subcat) => subcat.category == cat._id && !isDisabledSubCat(subcat)
  )
  return subcats.length > 0
}

function DotMenu() {
  return (
    <svg
      fill='currentColor'
      height='12px'
      width='12px'
      version='1.1'
      viewBox='0 0 32.055 32.055'
      className='rotate-90'
    >
      <g id='SVGRepo_bgCarrier' strokeWidth='0'></g>
      <g
        id='SVGRepo_tracerCarrier'
        strokeLinecap='round'
        strokeLinejoin='round'
      ></g>
      <g id='SVGRepo_iconCarrier'>
        <g>
          <path d='M3.968,12.061C1.775,12.061,0,13.835,0,16.027c0,2.192,1.773,3.967,3.968,3.967c2.189,0,3.966-1.772,3.966-3.967 C7.934,13.835,6.157,12.061,3.968,12.061z M16.233,12.061c-2.188,0-3.968,1.773-3.968,3.965c0,2.192,1.778,3.967,3.968,3.967 s3.97-1.772,3.97-3.967C20.201,13.835,18.423,12.061,16.233,12.061z M28.09,12.061c-2.192,0-3.969,1.774-3.969,3.967 c0,2.19,1.774,3.965,3.969,3.965c2.188,0,3.965-1.772,3.965-3.965S30.278,12.061,28.09,12.061z'></path>{' '}
        </g>
      </g>
    </svg>
  )
}

function ArrowIcon({
  isExpanded,
  size = 12,
}: {
  isExpanded: boolean,
  size?: number,
}) {
  return (
    <svg
      viewBox='0 0 1024 1024'
      width={size}
      height={size}
      className={`transition-transform duration-150 ${
        isExpanded ? 'rotate-[270deg]' : 'rotate-180'
      }`}
      fill='currentColor'
    >
      <path
        d='M768 903.232l-50.432 56.768L256 512l461.568-448 50.432 56.768L364.928 512z'
        fill='currentColor'
      ></path>
    </svg>
  )
}

function DropdoownItem({ name, icon, onClick }: any) {
  const { brandings } = useTheme()

  return (
    <div
      style={{ color: brandings.textColor }}
      className={`px-4 py-2 text-sm hover:bg-gray-800 flex items-center gap-3 cursor-pointer`}
      onClick={onClick}
    >
      {icon}
      {name}
    </div>
  )
}

function GroupTitle({
  title,
  disabled,
  category,
}: {
  title: string,
  disabled?: boolean,
  category: object,
}) {
  const { brandings } = useTheme()

  return (
    <div className='flex justify-between items-center mb-2.5'>
      <h2
        style={{ color: brandings.textColor }}
        className={`text-xs font-semibold m-0 uppercase ${
          disabled ? 'opacity-50 hover:text-gray-300 cursor-default' : ''
        }`}
      >
        {title}
      </h2>
    </div>
  )
}

function NavItem({
  title,
  children,
  active,
  disabled,
  expanded = false,
  doc,
  cat,
  sub_category,
}: {
  title: string,
  active?: boolean,
  disabled?: boolean,
  expanded?: boolean,
  doc?: object,
  cat?: object,
  sub_category?: object,
  children?: React.ReactNode,
}) {
  const { brandings, getHoverProps } = useTheme()
  const { setActiveDoc, setActiveCat, openSideMenu, setOpenSideMenu } = useDoc()
  const [isExpanded, setIsExpanded] = useState(false)

  const handleSelectedDoc = () => {
    if (disabled) return
    if (children) return setIsExpanded(!isExpanded)

    setActiveDoc(doc)
    setActiveCat(cat)
    setOpenSideMenu(!openSideMenu)
  }

  useEffect(() => {
    if (expanded) setIsExpanded(expanded)
  }, [expanded])

  return (
    <li className='text-sm'>
      <div
        style={{
          color: active ? brandings.textColor : brandings.textSecondaryColor,
          backgroundColor: active
            ? brandings.cardBgColor
            : brandings.pageBackground,
        }}
        {...getHoverProps({ active })}
        className={`flex items-center gap-4 group/dropdown rounded-md p-2 ${
          active ? brandings.cardBgColor : ''
        } ${
          disabled
            ? 'opacity-50 cursor-default'
            : `hover:bg-${brandings.buttonHoverColor} hover:rounded-md cursor-pointer`
        }`}
        onClick={handleSelectedDoc}
      >
        {children && (
          <div className=''>
            <ArrowIcon isExpanded={isExpanded} />
          </div>
        )}
        <span>{title}</span>
      </div>
      {isExpanded && children && (
        <ul className='pl-2 border-l border-gray-600'>{children}</ul>
      )}
    </li>
  )
}

function DocList({ sub_category, category }: any) {
  const AppService = useAppServices()
  const [docs, setDocs] = useState([])
  const [docsloader, setdocsloader] = useState(true)
  const { activeDoc, getLanguage } = useDoc()

  const getDocsData = async () => {
    setdocsloader(true)
    const { response } = await AppService.docs.get({
      query: `category=${sub_category.category}`,
    })
    if (response) {
      setDocs(response.data)
      setdocsloader(false)
    } else {
      setdocsloader(false)
    }
  }

  const onLoad = () => {
    getDocsData()
  }
  useEffect(() => {
    onLoad()
  }, [])

  const isActive = (doc) => {
    if (doc.type == 'agency' && doc.status === 'active') return true
    if (
      doc.type == 'super-admin' &&
      !doc?.snapshot?.status &&
      doc.status == 'active'
    )
      return true
    if (doc.snapshot?.status == 'active') return true
    return false
  }

  const filteredDoc = docs?.filter(
    (doc) => doc.sub_category == sub_category._id && isActive(doc)
  )

  return docsloader ? (
    <Spinner />
  ) : filteredDoc?.length ? (
    filteredDoc?.map((doc) => (
      <NavItem
        key={doc._id}
        title={getLanguage(doc, 'title')}
        doc={doc}
        cat={category}
        active={activeDoc._id == doc._id}
      />
    ))
  ) : (
    <div className='text-center text-xs font-medium'>No Docs Available</div>
  )
}

const PoweredBy = () => {
  const { brandings, generalInfos } = useTheme()
  return (
    <div
      style={{
        color: brandings.textColor,
        backgroundColor: brandings.pageBackground,
      }}
      className='flex sticky -mt-8 pt-8 bottom-0 z-10 pb-6  items-center justify-start text-xs font-semibold uppercase pr-6'
    >
      <svg
        fill='currentColor'
        height='30px'
        width='30px'
        version='1.1'
        viewBox='0 0 487.5 487.5'
      >
        <g id='SVGRepo_bgCarrier' strokeWidth='0'></g>
        <g
          id='SVGRepo_tracerCarrier'
          strokeLinecap='round'
          strokeLinejoin='round'
        ></g>
        <g id='SVGRepo_iconCarrier'>
          <g>
            <g>
              <path d='M437,12.3C437,5.5,431.5,0,424.7,0H126.3C84.4,0,50.4,34.1,50.4,75.9v335.7c0,41.9,34.1,75.9,75.9,75.9h298.5 c6.8,0,12.3-5.5,12.3-12.3V139.6c0-6.8-5.5-12.3-12.3-12.3H126.3c-28.3,0-51.4-23.1-51.4-51.4S98,24.5,126.3,24.5h298.5 C431.5,24.5,437,19,437,12.3z M126.3,151.8h286.2V463H126.3c-28.3,0-51.4-23.1-51.4-51.4V131.7 C88.4,144.2,106.5,151.8,126.3,151.8z'></path>{' '}
              <path d='M130.5,64.8c-6.8,0-12.3,5.5-12.3,12.3s5.5,12.3,12.3,12.3h280.1c6.8,0,12.3-5.5,12.3-12.3s-5.5-12.3-12.3-12.3H130.5z'></path>
              <path d='M178,397.7c6.3,2.4,13.4-0.7,15.8-7.1l17.9-46.8h62.7c0.5,0,0.9-0.1,1.3-0.1l17.9,46.9c1.9,4.9,6.5,7.9,11.4,7.9 c1.5,0,2.9-0.3,4.4-0.8c6.3-2.4,9.5-9.5,7.1-15.8l-54-141.2c-3-7.9-10.4-13-18.8-13c-8.4,0-15.8,5.1-18.8,13l-54,141.2 C168.5,388.2,171.7,395.2,178,397.7z M243.7,260l22.7,59.3h-45.3L243.7,260z'></path>{' '}
            </g>
          </g>
        </g>
      </svg>
      <span className='ml-2'>Powered By {generalInfos.poweredBy}</span>
    </div>
  )
}

function SearchButton({ setSearchQuery }) {
  const { brandings } = useTheme()
  return (
    <div className='w-full mb-8 sticky top-0 z-10'>
      <div className='absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none'>
        <SearchIcon />
      </div>
      <Input
        type='text'
        id='voice-search'
        style={{ color: brandings.textColor }}
        className=' w-full ps-10 p-2.5 py-2'
        placeholder='Ask or Search...'
        required
        onChange={(e) => setSearchQuery(e.target.value)}
      />
    </div>
  )
}

function Sidebar() {
  const { data, activeDoc, getLanguage } = useDoc()
  const { brandings } = useTheme()
  const { openSideMenu } = useDoc()
  const { agency } = useAgency()
  const [searchQuery, setSearchQuery] = useState('')

  return (
    <div className='flex-col md:flex-row flex'>
      <aside
        style={{
          backgroundColor: brandings.pageBackground,
        }}
        className={`w-64 h-[calc(100vh-200px)] min-h-96 flex flex-col fixed 
           transition-transform ${
             openSideMenu ? 'translate-x-0' : '-translate-x-full'
           } lg:relative top-51% left-0 lg:translate-x-0`}
      >
        {/* Scrollable Content */}
        <div className='flex-1 overflow-auto p-6 pb-0 custom-scrollbar'>
          <SearchButton setSearchQuery={setSearchQuery} />

          {data?.category
            ?.filter(
              (cat) =>
                !isDisabled(cat) &&
                hasSubCat(cat, data) &&
                (getLanguage(cat, 'name')
                  .toLowerCase()
                  .includes(searchQuery.toLowerCase()) ||
                  data?.sub_category?.filter(
                    (subcat) =>
                      !isDisabledSubCat(subcat) &&
                      getLanguage(subcat, 'name')
                        .toLowerCase()
                        .includes(searchQuery.toLowerCase())
                  ).length)
            )
            .map((category) => {
              return (
                <div key={category._id}>
                  <GroupTitle
                    title={getLanguage(category, 'name')}
                    category={category}
                  />
                  <ul className='space-y-2 mb-8'>
                    {data?.sub_category
                      ?.filter(
                        (subcat) =>
                          !isDisabledSubCat(subcat) &&
                          getLanguage(subcat, 'name')
                            .toLowerCase()
                            .includes(searchQuery.toLowerCase())
                      )
                      .map(
                        (sub_cat) =>
                          category._id == sub_cat.category && (
                            <NavItem
                              key={sub_cat._id}
                              title={getLanguage(sub_cat, 'name')}
                              active={sub_cat._id === activeDoc.sub_category}
                              expanded={sub_cat._id === activeDoc.sub_category}
                              sub_category={sub_cat}
                            >
                              <DocList
                                sub_category={sub_cat}
                                category={category}
                              />
                            </NavItem>
                          )
                      )}
                  </ul>
                </div>
              )
            })}
          {agency?.generalInfo && <PoweredBy />}
        </div>
      </aside>
    </div>
  )
}

export default Sidebar
