import React from 'react'
import { useTheme } from '../../../context/theme.context'

function Input({ className, ...props }: any) {
  const { brandings } = useTheme()
  return (
    <input
      className={`w-full px-2.5 py-1.5 rounded-sm transition-all ease-in ${
        className ?? ''
      }`}
      style={{
        border: `1px solid ${brandings.textFieldBorderColor}`,
        color: brandings.textFieldTextColor,
        backgroundColor: brandings.textFieldBgColor,
      }}
      {...props}
    />
  )
}

export default Input
