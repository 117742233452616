import React, { useEffect, useState } from 'react'
import Docs from './components/Docs'
import Navbar from '../../components/Navbar'
import Banner from '../../components/Banner'
import Loader from '../../components/Loader'
import { useAppServices } from '../../hook/services'
import { useLanguageInfo } from '../../context/language'
import GitBookLayout from './Gitbook'

const index = ({ agency }) => {
  const [language] = useLanguageInfo()
  return (
    <>
      <GitBookLayout />

      {/* <div className='body_wrapper'>
      <Navbar agency={agency} />
      <Banner />
      <Docs seleted_language={language} />
    </div> */}
    </>
  )
}

export default index
