import React from 'react'
import { TABS, useDoc } from './doc.context'
import { useTheme } from '../../context/theme.context'
import Button from './comp/Button'

function TabItem({ active = false, label = '', onClick }: any) {
  const { brandings, getHoverProps } = useTheme()

  return (
    <li onClick={onClick} className='me-2 '>
      <div
        style={{
          color: active ? brandings.textColor : brandings.textSecondaryColor,
        }}
        {...getHoverProps({ active })}
        className={`inline-block cursor-pointer mx-2 px-1.5 pb-0.5 border-b-2 rounded-t-lg ${
          active
            ? 'border-white active'
            : 'border-transparent hover:border-gray-300'
        }`}
      >
        {label}
      </div>
    </li>
  )
}

function Tabs() {
  const { brandings } = useTheme()
  const { activeTab, setActiveTab } = useDoc()

  const handleTab = (tab) => {
    setActiveTab(tab)
  }

  const copyCustomMenuLink = () => {
    const text = `${window.location.origin}/docs`

    navigator.clipboard
      .writeText(text)
      .then(() => {
        alert('Link copied to clipboard')
      })
      .catch((err) => {
        console.error('Failed to copy: ', err)
      })
  }

  return (
    <div className='p-4'>
      <div
        style={{ color: brandings.textSecondaryColor }}
        className='text-sm font-medium text-center border-b border-gray-700'
      >
        <div className='flex items-center justify-between'>
          <ul className='flex flex-wrap -mb-px'>
            <TabItem
              label='Documents'
              onClick={() => handleTab(TABS.DOC)}
              active={activeTab === TABS.DOC}
            />
            <TabItem
              label='FAQs'
              onClick={() => handleTab(TABS.FAQ)}
              active={activeTab === TABS.FAQ}
            />
          </ul>
        </div>
      </div>
    </div>
  )
}

export default Tabs
