import React from 'react'
import PropTypes from "prop-types";

const Loader = ({ image, title }) => {
    return (
        <div id="preloader">
            <div id="ctn-preloader" className="ctn-preloader">
                <div className="round_spinner">
                    <div className="spinner"></div>
                    <div className="text">
                        {
                            image &&
                            <img src={image} alt="" />
                        }
                        {
                            title &&
                            <h4><span>{title}</span></h4>
                        }
                    </div>
                </div>
                {/* <h2 className="head">Did You Know?</h2> */}
                <p></p>
            </div>
        </div>
    )
}

export default Loader
Loader.propTypes = {
    image: PropTypes.string,
    title: PropTypes.string,
};